import { BsGithub, BsTwitter } from "react-icons/bs";
import { SiIndeed } from "react-icons/si";
import { BiLogoLinkedin } from "react-icons/bi";
import dsc from "../../images/dscdev.jpg";
import mobile from "../../images/mobiledev.jpg";
import web from "../../images/webdev3.jpg";
import xgweather from "../../images/xgweather.png";
import bose from "../../images/bose.png";
import dscplaystore from "../../images/dscplaystore.jpg";
import expensetracker from "../../images/expensetracker.PNG";
import solemate from "../../images/solemate.png";
import fivestar from "../../images/fivestar.PNG";
import wine from "../../images/wine.jpg";
import gadget from "../../images/gadget.png";
import myrecipediary from "../../images/myrecipediary.jpg";
import weather from "../../images/weather.PNG";
import traveltube from "../../images/traveltube.PNG";
import traveltubeios from "../../images/traveltubeios.PNG";
import womencolor from "../../images/womencolor.jpg";
import womenhor from "../../images/womenhor.jpg";
import recipe from "../../images/recipe.jpg";
import climate from "../../images/climate.png";
import clusters from "../../images/clusters.png";
import vitamin from "../../images/vitamin.jpg";
import range from "../../images/range.png";
import portfolio from "../../images/portfolio.PNG";
import monify from "../../images/monify.PNG";
import lstmproject from "../../images/lstmproject.png";
import weathermonitor from "../../images/weathermonitor.jpeg";
import ratemate from "../../images/ratemate.jpeg";

export const userProfile = {
  username: "Adedoyin Idris",
  profileLinks: [
    {
      name: "Github",
      link: "https://github.com/devAdedoyin/",
      icon: <BsGithub />,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/in/adedoyin-idris-58448012b/",
      icon: <BiLogoLinkedin />,
    },
    // {
    //   name: "Twitter",
    //   link: "https://twitter.com/DreezyBines",
    //   icon: <BsTwitter />,
    // },
    // {
    //   name: "Indeed",
    //   link: "https://profile.indeed.com/?hl=en_GB&co=GB&from=gnav-homepage",
    //   icon: <SiIndeed />,
    // },
  ],
  homeDesc:
    "Welcome to my portfolio! Here you'll find a collection of my work and accomplishments. From software development, data science projects to creative writing, I've poured my passion into various domains. With a focus on problem-solving and a commitment to quality, I strive to deliver innovative and impactful solutions. Feel free to explore and get a glimpse into the length and breadth of my skills and experiences.",
  short_desc:
    "I'm Adedoyin Idris, a versatile professional in web development, data science, and mobile app development. I craft captivating websites, derive valuable data insights, and create user-friendly apps for Android and iOS. My portfolio showcases successful projects and a dedication to excellence.",
  long_desc:
    "As a web developer, data scientist, and mobile app developer, my skills span across three dynamic domains. In web development, I create visually stunning and responsive websites with seamless functionality. As a data scientist, I excel in deriving valuable insights from complex datasets using advanced techniques and machine learning algorithms. In mobile app development, I deliver feature-rich and user-friendly applications for Android and iOS platforms. My portfolio reflects a track record of successful projects and showcases my dedication to excellence in every endeavor. I eagerly look forward to collaborating on new challenges and exploring innovative opportunities.",
  userData: [
    // { data: "Israel", title: "Preferred name" },
    { data: "devadedoyin@gmail.com", title: "Email" },
    { data: "https://devadedoyin.com", title: "Website" },
    // { data: "+447300850614", title: "Phone number" },
    { data: "London, United Kingdom", title: "City" },
    { data: "MSc Data Science(distinction)", title: "Degree" },
    { data: "23rd June", title: "D.O.B" },
    { data: "Remote/Hybrid/Contract", title: "Availability" },
  ],
  services: [
    { img: mobile, title: "Mobile App Development" },
    { img: web, title: "Web Development" },
    { img: dsc, title: "Data Science & Analytics" },
  ],
  skills: [
    "Dart",
    "Flutter",
    "Tensorflow",
    "Microsoft Excel",
    "SQL",
    "Javascript",
    "React",
    "Maths and Statistics",
    "Git",
    "Java",
    "Kotlin",
    "Python",
    "Django",
    "Django Rest Framework",
  ],
  experiences: [
    {
      title: "Software Engineer",
      company: "PIIS",
      worktype: "Contract",
      date: "Nov 2021 - Oct 2022",
      length: "1 yr",
      description: [
        "Developed, tested, debugged, and documented RESTful APIs that served web and mobile application projects.",
        "Carried out unit tests, integration tests, and widget tests on code base for mobile applications and Restful APIs.",
        "Developed and deployed both RESTful APIs and mobile applications that served as solutions for shipping services.",
      ],
    },
    {
      title: "Mobile Application Developer",
      company: "Outfit Technologies",
      worktype: "Freelance",
      date: "Mar 2021 - Feb 2022",
      length: "1 yr",
      description: [
        "Developed mobile applications to help provide solutions to the health care system.",
        "Collaborated with project managers to select ambitious, realistic coding milestones on pre-release software project development.",
        "Translated designs and wireframes into high-quality code.",
      ],
    },
  ],
  portfolio: {
    short_desc:
      "Dive into my diverse projects! As a web developer, data scientist, and mobile app enthusiast, each creation reflects my passion for innovation. Let's explore together! 🚀",
    cats: ["All", "Mobile Apps", "Website", "Data Science"],
    projects: [
      {
        name: "The Gadget Store App",
        category: "mobile",
        ios: null,
        android:
          "https://github.com/DevAdedoyin/gadget_store?tab=readme-ov-file#gadget-store",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: gadget,
        // displayImageIos: cometios,
      },
      {
        name: "BOSE Headphones Animation",
        category: "mobile",
        ios: null,
        android:
          "https://github.com/DevAdedoyin/BOSE-ecommerce-animation?tab=readme-ov-file#bose-headphones-e-commerce-animation-app",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: bose,
        // displayImageIos: cometios,
      },
      // {
      //   name: "The Five Star Logistics Ltd App",
      //   category: "mobile",
      //   ios: "https://apps.apple.com/ro/app/five-star-logistics-ltd-app/id1639915648?platform=iphone",
      //   android:
      //     "https://play.google.com/store/apps/details?id=com.fivestarlogistics",
      //   isMobile: true,
      //   hasIos: true,
      //   isWeb: false,
      //   isDsc: false,
      //   displayImage: fivestar,
      //   displayImageIos: fivestarios,
      // },
      // {
      //   name: "H Space App",
      //   category: "mobile",
      //   // TODO Remeber to add or remove the ios link
      //   ios: "https://apps.apple.com/ro/app/five-star-logistics-ltd-app/id1639915648?platform=iphone",
      //   android: "https://play.google.com/store/apps/details?id=com.clemac",
      //   isMobile: true,
      //   hasIos: true,
      //   isWeb: false,
      //   isDsc: false,
      // },
      {
        name: "Travel Tube",
        category: "mobile",
        ios: "https://apps.apple.com/app/id1580895770?platform=iphone",
        android:
          "https://play.google.com/store/apps/details?id=com.traveltube.tclubmobile",
        isMobile: true,
        hasIos: true,
        isWeb: false,
        isDsc: false,
        displayImage: traveltube,
        displayImageIos: traveltubeios,
      },
      {
        name: "My Recipe Diary",
        category: "mobile",
        ios: null,
        android:
          "https://play.google.com/store/apps/details?id=com.pauversildo.myrecipediary",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: myrecipediary,
        // displayImageIos: cometios,
      },
      {
        name: "Sole Mate",
        category: "mobile",
        ios: null,
        android: "https://github.com/DevAdedoyin/sole_mate#sole-mate",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: solemate,
        // displayImageIos: cometios,
      },
      {
        name: "A Weather Monitor App",
        category: "mobile",
        ios: null,
        android:
          "https://play.google.com/store/apps/details?id=com.weathermonitor.weatherapp",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: weathermonitor,
        // displayImageIos: cometios,
      },
      {
        name: "RateMate",
        category: "mobile",
        ios: null,
        android:
          "https://play.google.com/store/apps/details?id=com.pauversil.ratemate",
        isMobile: true,
        hasIos: false,
        isWeb: false,
        isDsc: false,
        displayImage: ratemate,
        // displayImageIos: cometios,
      },
      // {
      //   name: "Farm Konnect",
      //   category: "mobile",
      //   ios: null,
      //   android:
      //     "https://play.google.com/store/apps/details?id=com.cloudware.farmkonnect",
      //   isMobile: true,
      //   hasIos: false,
      //   isWeb: false,
      //   isDsc: false,
      //   displayImage: farmkonnect,
      //   // displayImageIos: cometios,
      // },
      {
        name: "Expense Tracker",
        category: "web",
        web: "https://devadedoyin.github.io/ExpenseTrackerSite/",
        isMobile: false,
        isWeb: true,
        isDsc: false,
        displayImage: expensetracker,
        // displayImageIos: cometios,
      },
      {
        name: "Monify",
        category: "web",
        web: "https://devadedoyin.github.io/monify/",
        isMobile: false,
        isWeb: true,
        isDsc: false,
        displayImage: monify,
        // displayImageIos: cometios,
      },
      {
        name: "My Portfolio Website",
        category: "web",
        web: "https://devadedoyin.com",
        isMobile: false,
        isWeb: true,
        isDsc: false,
        displayImage: portfolio,
        // displayImageIos: cometios,
      },
      {
        name: "Weather Monitor",
        category: "web",
        web: "http://hammerhead-app-4gqe5.ondigitalocean.app",
        isMobile: false,
        isWeb: true,
        isDsc: false,
        displayImage: weather,
        // displayImageIos: cometios,
      },
      {
        name: "Recipe API",
        category: "web",
        web: "https://github.com/DevAdedoyin/recipe-app-api",
        isMobile: false,
        isWeb: true,
        isDsc: false,
        displayImage: recipe,
        // displayImageIos: cometios,
      },
      {
        name: "The frequency of app categories in the Google Playstore",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-bar-plot-displaying-the-frequency-of-apps-in-each-category-of-the-google-play-store",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: dscplaystore,
        // displayImageIos: cometios,
      },
      {
        name: "Weather Prediction Using XGBoost",
        category: "dsc",
        dsc: "https://colab.research.google.com/drive/1_0LcaFWE1EohALEzuye19gL-eEZbwGAm?authuser=2#scrollTo=-L4cD0n_nmG3",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: xgweather,
        // displayImageIos: cometios,
      },
      {
        name: "Wine Quality Prediction Using Random Forest & SVC",
        category: "dsc",
        dsc: "https://colab.research.google.com/drive/1snlUFDjCLdaKfc5C01VzjLFNWTWpWQ5u?authuser=2#scrollTo=-zcPNHxKErtC",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: wine,
        // displayImageIos: cometios,
      },
      {
        name: "Stock Market Analysis",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/Stock-Market-Analysis/blob/master/Stock_Market_Analysis.ipynb",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: lstmproject,
        // displayImageIos: cometios,
      },
      {
        name: "Women in the house of representative",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-line-plot-displaying-the-number-of-women-in-the-usa-house-of-representative-from-the-year-1975-to-2019",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: womenhor,
        // displayImageIos: cometios,
      },
      {
        name: "Women of color in the USA congress",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-pie-plot-showing-the-number-of-women-of-various-ethnicity-in-the-usa-congress",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: womencolor,
        // displayImageIos: cometios,
      },
      {
        name: "Climate Change Statistics and Trends",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/climate-change-project/blob/master/README.md",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: climate,
        // displayImageIos: cometios,
      },
      {
        name: "Cluster Analysis of Urban Population Growth",
        category: "dsc",
        dsc: "https://github.com/DevAdedoyin/clustering-and-fitting/blob/master/README.md",
        isMobile: false,
        isWeb: false,
        isDsc: true,
        displayImage: clusters,
        // displayImageIos: cometios,
      },
    ],
  },
  articleDesc:
    "Welcome to my article page! Delve into a treasure trove of insights and knowledge on web development, data science, and mobile app development. Explore thought-provoking topics and stay updated with the latest trends. Let's embark on a journey of continuous learning and innovation together. Happy reading! 📚🚀",
  // article
  articleList: [
    {
      name: "A Semantic Based Vitamin Deficiency Monitoring System",
      link: "https://dl.acm.org/doi/10.1145/3384544.3384598",
      image: vitamin,
    },
    {
      name: "The frequency of app categories in the Google Playstore",
      link: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-bar-plot-displaying-the-frequency-of-apps-in-each-category-of-the-google-play-store",
      image: dscplaystore,
    },
    {
      name: "Women in the house of representative",
      link: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-line-plot-displaying-the-number-of-women-in-the-usa-house-of-representative-from-the-year-1975-to-2019",
      image: womenhor,
    },
    {
      name: "Women of color in the USA congress",
      link: "https://github.com/DevAdedoyin/Applied-Data-Science-Project/blob/master/README.md#a-pie-plot-showing-the-number-of-women-of-various-ethnicity-in-the-usa-congress",
      image: womencolor,
    },
    {
      name: "Climate Change Statistics and Trends",
      link: "https://github.com/DevAdedoyin/climate-change-project/blob/master/README.md",
      image: climate,
    },
    {
      name: "Cluster Analysis of Urban Population Growth",
      link: "https://github.com/DevAdedoyin/clustering-and-fitting/blob/master/README.md",
      image: clusters,
    },
    {
      name: "The range() function in Python",
      link: "https://medium.com/@israeladedoyin23/5661ca6e649d",
      image: range,
    },
  ],
};
